export type ErrorDetail = {
	[errorType: string]: number[] | any;
};

export type ErrorServer = {
	errors: string[];
	status: number;
	error_detail?: ErrorDetail[];
	active_phone?: string;
	time?: number;
};

export type ErrorServerV2 = {
	errors: string[];
	status: number;
	error_detail?: ErrorDetail[];
	active_phone?: string;
	time?: number;
};

class Error {
	statusCode: number;
	errors: string[];
	error_detail?: ErrorDetail[];
	active_phone?: string;
	time?: number;
	url?: string;

	constructor({
		statusCode,
		errors,
		error_detail,
		active_phone,
		time,
		url,
	}: {
		statusCode: number;
		errors: string[];
		error_detail?: ErrorDetail[];
		active_phone?: string;
		time?: number;
		url?: string;
	}) {
		this.statusCode = statusCode;
		this.errors = errors;
		this.error_detail = error_detail;
		this.active_phone = active_phone;
		this.time = time;
		this.url = url;
	}
}

export default Error;
